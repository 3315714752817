import React from 'react';
import Layout from '~/src/components/Layout';
import SubscriptionTerms from '~/src/components/SubscriptionTerms';

const GatsbySubscriptionTermsPage: React.FC = () => {
  return (
    <Layout>
      <SubscriptionTerms />
    </Layout>
  );
};

export default GatsbySubscriptionTermsPage;
