import React from 'react';
import { Helmet } from 'react-helmet-async';

const SubscriptionTerms: React.FC = () => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css" />
      </Helmet>
      <div className="container" style={{ fontFamily: `'Times New Roman', Times, serif`, paddingTop: '15px', textAlign: 'justify' }}>
        <h1 style={{ fontSize: '2rem' }}>Subscription Terms for Solitaire: Decked Out</h1>
        <span>Effective as of December 7, 2020</span>
        <hr />
        <p>You can purchase a Monthly, Quarterly, or Annual Subscription Plan to Solitaire: Decked Out to get access to
            various perks, including an ad-free experience and certain unlockable items available only to subscribers.
            Such perks for a given Subscription Plan are available for one device only.
        </p>

        <p>If you purchase a Subscription Plan, then your Subscription Plan will begin immediately, and you are entering
            into a periodic subscription contract with Devsisters Corporation. You are also authorizing a charge to you
            of a periodic subscription fee at the rate set forth above after any free trial period. For subscription
            services purchased on a platform such as Apple or Google, the platform will charge you for the subscription
            fee and the platform’s payment terms will apply. Please review the appropriate platform’s payment terms for
            additional information.
        </p>

        <p>Subscription charges will be applied to the payment method you provide when you start your paid subscription
            period (or to a different payment instrument, if you change your account information). Please note that
            prices are subject to change.
        </p>

        <p>Your Subscription Plan will automatically renew for the same period as your existing Subscription Plan at the
            end of the term of each Subscription Plan unless and until you terminate your Subscription Plan. You must
            cancel your Subscription Plan before it renews, otherwise payment for the next Subscription Plan will be
            charged automatically to your payment method.
        </p>

        <p>You can manage or cancel your Subscription Plan by going to your Google Play or iTunes Account Settings.
        </p>

        <p>PAYMENTS FOR SUBSCRIPTION PLANS ARE NONREFUNDABLE AND THERE ARE NO REFUNDS OR CREDITS FOR PARTIALLY USED
            SUBSCRIPTION PLANS.
        </p>

        <p>All use of Solitaire: Decked Out is governed by Devsisters’ Terms of Service and Privacy Policy.</p>
      </div>
    </>
  )
};

export default SubscriptionTerms;
